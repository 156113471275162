/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/692c5412c7c077f9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6f51bd087744144e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6991ff46d90fc8f5-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/548889ae31a30f45-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2055a3bc9372cf01-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f1f13f4234669585-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a4a78f32e6d6bdeb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/692c5412c7c077f9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6f51bd087744144e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6991ff46d90fc8f5-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/548889ae31a30f45-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2055a3bc9372cf01-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1f13f4234669585-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a4a78f32e6d6bdeb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/692c5412c7c077f9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6f51bd087744144e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6991ff46d90fc8f5-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/548889ae31a30f45-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2055a3bc9372cf01-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f1f13f4234669585-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a4a78f32e6d6bdeb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/692c5412c7c077f9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6f51bd087744144e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6991ff46d90fc8f5-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/548889ae31a30f45-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2055a3bc9372cf01-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f1f13f4234669585-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a4a78f32e6d6bdeb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_d4a1eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_d4a1eb';src: local("Arial");ascent-override: 109.21%;descent-override: 42.66%;line-gap-override: 0.00%;size-adjust: 93.76%
}.__className_d4a1eb {font-family: '__Source_Sans_3_d4a1eb', '__Source_Sans_3_Fallback_d4a1eb'
}.__variable_d4a1eb {--font-source-sans-3: '__Source_Sans_3_d4a1eb', '__Source_Sans_3_Fallback_d4a1eb'
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/5429b4e44472baf3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/09ff69ebe8584338-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/a9131150a29f9402-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/0bb08d17b17e0483-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/6d279439c4de6a8a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/1aac8753ad3fc5dd-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/d423b8cc6998a42d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/68bcdf2c4a5b6e7f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/87938583ae89d1fb-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/418a802095ad2e9e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/5f01b218f380ae75-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/c18a90a9af05f5b2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/c3269895b5842c5e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__DIN_8af58a';
src: url(/_next/static/media/f78f1c8938146eb1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: italic;
}@font-face {font-family: '__DIN_Fallback_8af58a';src: local("Arial");ascent-override: 72.18%;descent-override: 28.91%;line-gap-override: 20.22%;size-adjust: 98.91%
}.__className_8af58a {font-family: '__DIN_8af58a', '__DIN_Fallback_8af58a'
}.__variable_8af58a {--font-din: '__DIN_8af58a', '__DIN_Fallback_8af58a'
}

@font-face {
font-family: '__DinSemiCond_5504e0';
src: url(/_next/static/media/c5286167b91f5af3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__DinSemiCond_Fallback_5504e0';src: local("Arial");ascent-override: 79.70%;descent-override: 31.92%;line-gap-override: 22.32%;size-adjust: 89.59%
}.__className_5504e0 {font-family: '__DinSemiCond_5504e0', '__DinSemiCond_Fallback_5504e0';font-weight: 700;font-style: normal
}.__variable_5504e0 {--font-din-semi-cond: '__DinSemiCond_5504e0', '__DinSemiCond_Fallback_5504e0'
}

